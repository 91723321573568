<template>
    <div class="height1">
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form :model="searchForm" :inline="true">
                            <el-form-item>
                                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions0" size="small" style="margin-right: 10px" @change="search">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item>
                                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="操作人"
                                    @keyup.enter.native="search" clearable ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search()">搜索</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <el-table :data="tableData" stripe height="calc(100% - 80px)" style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="alreadyShareMoney" label="分红金额（元）" width="150" align="center">
                    </el-table-column>
                    <el-table-column prop="shareMoney" label="现有交易用户分红金额（元）" align="center"></el-table-column>
                    <el-table-column prop="alreadyShareCount" label="已分笔数" align="center"></el-table-column>
                    <el-table-column prop="organizationName" label="操作人" align="center">
                    </el-table-column>
                    <el-table-column prop="lastTime" label="最近一次交易用户分红分润" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.lastTime | timefilters }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import bread from "../../../components/common/breadcrumb"
import { Distributor } from "../../../components/HospitalDomain/Distributor";
export default {
    name: "Bill",
    components: {
        bread
    },
    data() {
        var distributor = new Distributor(this.TokenClient, this.Services.Authorization);
        return {
            DistributorDomain: distributor,
            tableData: [],
            searchForm: {
                type: '0',
                statrTime: '',
                endTime: '',
                keyWord: '',
                datepicker: ''
            },
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            purpose: ['upgradeMember', 'renewalMember', 'buyMember'],
            organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
            userId: JSON.parse(sessionStorage.getItem('userInfo')).id,
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 15,
            DataTotal: 0,
            ShareDialog: false,
            formLabelWidth: '150px',
            scaleForm: {
                memberScale: 0,
                VIPMember1Scale: 0,
                VIPMember2Scale: 0,
            },
            percentInfo: {
                commonPercent: 0,
                vip1Percent: 0,
                vip2Percent: 0,
            },
            ShareData: {},
            ShareDetail: {},
            scaleDialog: false,
            rules: {
                commonPercent: [
                    { pattern: /(^[1-9]\d{0,1}$)/, message: "请输入1-99的整数", trigger: "blur" },
                    { required: true, message: '请输入普通会员分红比例', trigger: 'blur' },
                ],
                vip1Percent: [
                    { pattern: /(^[1-9]\d{0,1}$)/, message: "请输入1-99的整数", trigger: "blur" },
                    { required: true, message: '请输入VIP1会员分红比例', trigger: 'blur' },
                ],
                vip2Percent: [
                    { pattern: /(^[1-9]\d{0,1}$)/, message: "请输入1-99的整数", trigger: "blur" },
                    { required: true, message: '请输入VIP2会员分红比例', trigger: 'blur' },
                ]
            }
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        search(event) {
            this.PageIndex = 1;
            this.getList()
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.getList();
        },
        getList() {
            var _this = this;
            var item = _this.searchForm;
            if (_this.searchForm.datepicker) {
                item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00';
                item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59';
            } else {
                item.startTime = "";
                item.endTime = "";
            }
            _this.DistributorDomain.GetCustomerPublicShareAllOrg(0,item.keyWord,item.startTime, item.endTime, _this.PageIndex,
                function (data) {
                    _this.tableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.DataTotal = data.data.dataTotal;
                },
                function (error) {
                    console.log(error);
                });
        },
    }
}
</script>

<style scoped>
.el-input {
    width: 95%;
}


.iq-search-bar /deep/.el-descriptions__header {
    margin-bottom: 10px;
}
</style>
